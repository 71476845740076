import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import documentAPI from "../document/documentAPI";
import journalAPI from "./journalAPI";
import KPConfig from '../../KPConfig.js'
import { sanitizeFilenameInUrl } from '../../utils/StringSanitizer';
import { getGlobalAbortController } from "../../utils/abortController";

const initialState = {
  status: 'idle',
  downloadJournalStatus: 'idle',
  downloadJournalWeekStatus: 'idle',
  journal: {},
  journalEntries: [],
  sortedJournalEntries: [],
  journalPDFUrl: ""
}

export const getJournal = createAsyncThunk (
  'journal/getJournal',
  async (params) => {
    const response = await journalAPI.getJournal(params)
    return response.body
  }
)

export const getJournalEntries = createAsyncThunk (
  'journal/getJournalEntries',
  async (params) => {
    const response = await journalAPI.getJournalEntries(params)
    return response.body
  }
)

export const createNewJournal = createAsyncThunk (
  'journal/createNewJournal',
  async (params) => {
    const response = await journalAPI.newCreateNewJournal(params.parent_id, params.title, params.template_id)
    return response.body
  }
)

export const addNewJournalEntry = createAsyncThunk (
  'journal/addNewJournalEntry',
  async (params) => {
    const response = await journalAPI.newAddNewJournalEntry(params.journalId, params.copyPrevious)
    return response.body
  }
)

export const deleteJournal = createAsyncThunk (
  'journal/deleteJournal',
  async (params) => {
    const response = await journalAPI.deleteJournal(params)
    return response.body
  }
)

export const downloadJournalPdf = createAsyncThunk(
  'journal/downloadJournalPdf',
  async (params) => {

    let ts = params.ts * 1000
    const formattedDate = new Intl.DateTimeFormat('fi-FI', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(ts).replaceAll('.', '-');
    
    const documentName = sanitizeFilenameInUrl(`${params.documentId} ${params.objectTitle} ${formattedDate}.pdf`);
    const globalAbortController = getGlobalAbortController();

    const response1 = await documentAPI.getDocumentStatusId(params.documentId, 'pdf', 'pdf', globalAbortController);

    if (!response1) {
      return;
    }

    if (response1.statusCode !== 200) {
      throw new Error('Error getting journal status');
    }

    const { status_id } = response1.body || {};

    if (!status_id) {
      throw new Error('No status ID for PDF journal');
    }

    const processedDownloadId = await documentAPI.getPdfProcessedDownloadId(response1.body.status_id, globalAbortController);

    //We don't want to throw error if user has canceled the download
    if (processedDownloadId === "AbortError") {
      return;
    }

    if (!processedDownloadId) {
      throw new Error('No processedDownloadId for PDF journal');
    }

    let url = `${KPConfig.backendUrl}/pdf/download/${processedDownloadId}/${documentName}`;

    return url;
  }
);

export const downloadJournalWeekPdf = createAsyncThunk(
  'journal/downloadJournalWeekPdf',
  async (params) => {

    const documentPdfName = sanitizeFilenameInUrl(params.objectTitle + '_' + params.weekIdentifier + '.pdf');

    const response1 = await documentAPI.getDocumentStatusId(params.documentIds, 'pdf');

    if (response1.statusCode !== 200) {
      throw new Error('Error getting journal status');
    }
    
    const { status_id } = response1.body || {};
  
    if (!status_id) {
      throw new Error('No status ID for PDF journal');
    }

    const processedDownloadId = await documentAPI.getPdfProcessedDownloadId(response1.body.status_id);
    
    if (!processedDownloadId) {
      throw new Error('No processedDownloadId for PDF journal');
    }

    let url = `${KPConfig.backendUrl}/pdf/download/${processedDownloadId}/${documentPdfName}`;

    return documentAPI.downloadDocument(url, documentPdfName);
  }
);

export const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    addSortedEntries(state, action) {
      state.sortedJournalEntries = action.payload
    },
    resetJournal: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJournal.fulfilled, (state, action) => {
        state.journal = action.payload
      })

      .addCase(getJournalEntries.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getJournalEntries.fulfilled, (state, action) => {
        if (action.payload) {
          state.journalEntries = action.payload.results
        }
        state.status = 'idle'
      })
      .addCase(getJournalEntries.rejected, (state) => {
        state.status = 'failed'
      })

      .addCase(createNewJournal.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createNewJournal.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(createNewJournal.rejected, (state) => {
        state.status = 'failed'
      })

      .addCase(addNewJournalEntry.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(addNewJournalEntry.fulfilled, (state, action) => {
        state.journal = action.payload
        state.status = 'idle'
      })
      .addCase(addNewJournalEntry.rejected, (state) => {
        state.status = 'failed'
      })

      .addCase(deleteJournal.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteJournal.fulfilled, (state, action) => {
        state.journal = action.payload
        state.status = 'idle'
      })
      .addCase(deleteJournal.rejected, (state) => {
        state.status = 'failed'
      })

      // downloadPdf
      .addCase(downloadJournalPdf.pending, (state) => {
        state.downloadJournalStatus = 'loading';
        state.journalPDFUrl = "";
      })
      .addCase(downloadJournalPdf.fulfilled, (state, action) => {
        state.downloadJournalStatus = 'idle';
        state.journalPDFUrl = action.payload;
      })
      .addCase(downloadJournalPdf.rejected, (state) => {
        state.downloadJournalStatus = 'error';
      })

      // download weekly compilation Pdf
      .addCase(downloadJournalWeekPdf.pending, (state) => {
        state.downloadJournalWeekStatus = 'loading';
      })
      .addCase(downloadJournalWeekPdf.fulfilled, (state) => {
        state.downloadJournalWeekStatus = 'idle';
      })
      .addCase(downloadJournalWeekPdf.rejected, (state) => {
        state.downloadJournalWeekStatus = 'idle';
      })
  }
})

export const selectDownloadJournalStatus = (state) => state.journal.downloadJournalStatus;
export const selectDownloadJournalWeekStatus = (state) => state.journal.downloadJournalWeekStatus;
export const selectJournalPDFUrl = (state) => state.journal.journalPDFUrl;

export const {resetJournal, addSortedEntries} = journalSlice.actions
export default journalSlice.reducer