import { apiCall, NEW_BE_API } from '../../utils/APIFunctions';
import { replaceScaleValuesInHtml } from './shapes/ShapeFunctions';
import { decodeHtmlChars, escapeHtml } from '../../utils/HelperFunctions';

export function newGetMarkings(params) {
  let callPath = '';
  if (params.nodeId) {
    callPath = `${NEW_BE_API}/api/n1/markings/${params.objectId}?nodeId=${params.nodeId}&documentId=${params.documentId}`;
  } else {
    callPath = `${NEW_BE_API}/api/n1/markings/${params.objectId}`;
  }

  return apiCall(callPath, "get");
}

export function newSaveMarking(params) {
  const marking = JSON.parse(JSON.stringify(params.marking));
  /**
   * In old-ui, marking clicks don't work if there is sjx-drag.
   * Remove it here from deep-cloned copy to keep subjx work in real marking svg-object.
   * 
   * NOTE: We set marking.svg_object only to copied object. Not for marking in state.markings.
   */
  if (marking.svg_object_latest) {
    marking.svg_object = marking.svg_object_latest.replaceAll('sjx-drag', '');
  }
  if (marking.databaseId) {
    marking.id = marking.databaseId;
  }

  if (marking.svg_object_type === 'pointer') {
    // Save pointer always with scale-values in zoom-level 1
    marking.svg_object = replaceScaleValuesInHtml(marking.svg_object, marking.scale);
  }

  // Sometimes subjx behaves buggy and there is .sjx-svg-wrapper-element inside .svgDrawing. Make sure it is never saved.
  // Now this should also be fixed in code, but you can never be sure...
  const temp = document.createElement("div");
  temp.innerHTML = decodeHtmlChars(marking.svg_object);
  const sjxElemToRemove = temp.querySelector(".sjx-svg-wrapper");
  if (sjxElemToRemove) {
    sjxElemToRemove.remove();
    marking.svg_object = escapeHtml(temp.innerHTML);
  }


  const data = {
    markings: [marking],
    nodeId: params.nodeId,
    documentId: params.documentId
  };

  return apiCall(`${NEW_BE_API}/api/n1/markings/${params.objectId}`, "post", JSON.stringify(data));
}

export function newDeleteMarking(params) {
  return apiCall(`${NEW_BE_API}/api/n1/markings/${params.markingId}`, "delete");
}

export function newDeleteAllMarkings(params) {
  const url = params.nodeId && params.documentId ?
    `${NEW_BE_API}/api/n1/markings/${params.objectId}/all?&nodeId=${params.nodeId}&documentId=${params.documentId}` :
    `${NEW_BE_API}/api/n1/markings/${params.objectId}/all`;

  return apiCall(url, "delete");
}

export function newCreateMarkingContent(params) {
  return apiCall(`${NEW_BE_API}/api/n1/markings/${params.marking_id}/content`, "post", JSON.stringify(params));
}

export function newChangeMarkingContentParent(params) {
  return apiCall(`${NEW_BE_API}/api/n1/markings/${params.marking_id}/change-parent`, "post", JSON.stringify(params));
}

export function newCopyMarking(id) {
  return apiCall(`${NEW_BE_API}/api/n1/markings/${id}/copy`, "put");
}

export function newGetFloorPlanDrawSettings(floorPlanId) {
  return apiCall(`${NEW_BE_API}/api/n1/floor_plans/${floorPlanId}/draw_settings`, "get");
}