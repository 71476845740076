import { apiCall, API, NEW_BE_API } from '../../../utils/APIFunctions';

export function fetchUserDocumentStatistics() {
  return apiCall(`${API}/stats/user_documents`, "GET");
}

export function fetchUserMeasurementsStatistics() {
  return apiCall(`${API}/stats/measurements`, "GET");
}

export function fetchStartpageDocumentStatistics() {
  return apiCall(`${NEW_BE_API}/api/n1/documents/startpage-stats`, "GET");
}
  