import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import * as folderSlice from '../folder/folderSlice';
import * as api from './objectsAPI';
import { buildObjectsTree, evaluateRights, buildObjectPath } from './objectsFunctions';
import Constants from '../../app/constants.js'
import isEqual from 'lodash/isEqual';

const removeObjectFromCurrentTree = (state, id) => {
  if (state.objectsMapById[state.currentFolder?.id]?.objects?.[id]) {
    delete state.objectsMapById[state.currentFolder?.id].objects[id];
  }
  if (state.currentFolderSelectableObjectIds[id]) {
    delete state.currentFolderSelectableObjectIds[id];
  }
  if (state.currentFolderSelectedObjectIds[id]) {
    delete state.currentFolderSelectedObjectIds[id];
  }
  if (state.currentFolderVisibleObjects[id]) {
    delete state.currentFolderSelectedObjectIds[id];
  }
}

const initialState = {
  status: 'idle',
  deleteStatus: 'idle',
  fetchStatus: 'idle',
  fetchStatusPartOne: 'idle',
  statusById: {},
  objectsById: {},
  objectsMapById: {},
  fetchedTs: 0,
  managerInCompanyFeatures: {},
  currentFolder: {},  // We need id and company_id of this
  currentFolderVisibleObjects: {},
  currentFolderSelectableObjectIds: {},
  currentFolderSelectedObjectIds: {},
  copyParentRights: true,
  templateList: [],
  templateIdCounts: {},
  superfolderIdCounts: {},
  draggableObject: null,
  folderSettings: null,
  objectTagsModalObject: null,
  saveTagsStatus: 'idle',
  folderTreeSearchString: '',
  folderTreeSearchHits: [],
  createStep: 1,
  objectCreateTargetFolderPath: [],
  deleted: {}, // We store deleted objects here, so we can restore them if delete fails
  favoriteObjects: [],
  fetchFavoriteObjectsStatus: 'idle',
  favoriteStatuses: {},
  userObjects: [],

  sharedFromCompanies: [],

  statusCounts: {},
  objectIdsForStatusQuery: [],
  startpageObjectIdsForStatusQuery: [], // Avoid mess with folderViews and put this to own variable. Possibly we could use same variable for all...
  folderAltInfoStatus: 'idle',
  objectTags: {}
};

export const fetchObjects = createAsyncThunk(
  'objects/fetch',
  async (opts, { getState }) => {
    let response = await api.newFetch(opts);

    if (getState().objects.folderSettings === null) {
      const settingsResponse = await api.fetchFolderSettings();
      response.body.folderSettings = settingsResponse.body;
    }
    response.body.userId = getState().auth.data.user_id; //Used for filtering user objects

    return response.body;
  },
  { condition: (opts) => {
      // This will be run before the pending reducer

      return !opts?.skipFetch;
    }
  }
);

/**
 * Gets the list of companies which user has objects shared from
 */
export const fetchSharedFromCompaniesList = createAsyncThunk(
  'objects/fetchSharedFromCompaniesList',
  async () => {
    const response = await api.fetchSharedFromCompaniesList();
    return response.body;
  }
);

export const deleteObject = createAsyncThunk(
  'objects/deleteObject',
  async (params) => {
    let promiseArr = params.objectIds;
    let mostRecentPromise = promiseArr.reduce((previousPromise, objectId) => {
      return previousPromise.then(() => {
        return api.deleteObject(objectId);
      });
    }, Promise.resolve([]));

    return mostRecentPromise;
  }
);

export const moveObject = createAsyncThunk(
  'objects/moveObject',
  async (params, { dispatch, getState }) => {
    dispatch(folderSlice.invalidateFolderTree());

    let copyParentRights = getState().objects.copyParentRights;

    if (Object.prototype.hasOwnProperty.call(params, 'copyParentRights')) {
      copyParentRights = params.copyParentRights;
    }

    let promiseArr = params.objectIds;
    let mostRecentPromise = promiseArr.reduce((previousPromise, objectId) => {
      return previousPromise.then(() => {
        return api.moveObject(objectId, params.targetObjectId, copyParentRights);
      });
    }, Promise.resolve([]));

    return mostRecentPromise.then(() => {
      dispatch(fetchObjects({parentId: getState()?.objects?.currentFolder?.id || 0}));
    });
  }
);

/**
 * TODO: Move this into documentSlice?
 */
export const createDocument = createAsyncThunk(
  'objects/createDocument',
  async (params, { dispatch, getState }) => {
    const response = await api.newCreateDocument(params);

    dispatch(fetchObjects({parentId: getState()?.objects?.currentFolder?.id || 0}));

    return response.body;
  }
);

export const saveObject = createAsyncThunk(
  'objects/saveObject',
  async (params) => {
    const response = await api.saveObject(params);
    return response.body;
  }
);

export const savePropagateViewOnShare = createAsyncThunk(
  'objects/savePropagateViewOnShare',
  async (params) => {
    const response = await api.savePropagateViewOnShare(params);
    response.body.id = params.id;
    return response.body;
  }
);

export const updateFolderSettings = createAsyncThunk(
  'objects/updateFolderSettings',
  async (params, { getState }) => {
    params.id = getState().objects.currentFolder.id;
    if (!params.general) {
      // There is two types of folder settings: "general" (without user_id) and user specific
      // alt_info_id is saved as "general" setting. Ordering is user specific.
      params.user_id = getState().auth.data.user_id;
    }
    const response = await api.updateFolderSettings(params);
    return response.body;
  }
);

export const getFolderAltInfoId = createAsyncThunk(
  'objects/getFolderAltInfoId',
  async (objectId) => {
    const response = await api.getFolderAltInfoId(objectId);
    return response.body;
  }
);

export const saveObjectTags = createAsyncThunk(
  'objects/saveObjectTag',
  async (updateOnlyFolders, { getState }) => {
    let promiseArr = getState().objects.objectTags[getState().objects.objectTagsModalObject?.id];
    const objectId = getState().objects.objectTagsModalObject.id;
    let mostRecentPromise = promiseArr.reduce((previousPromise, tag) => {
      return previousPromise.then(() => {
        let tagValue = { key: tag.key, value: tag.value, updateOnlyFolders: (updateOnlyFolders ? "1" : "0") };
        return api.saveObjectTag(objectId, tag.id, tagValue);
      });
    }, Promise.resolve([]));

    await mostRecentPromise;

    return {id: objectId};
  }
);

export const fetchFavoriteObjects = createAsyncThunk(
  'objects/fetchFavoriteObjects',
  async () => {
    const response = await api.fetchFavoriteObjects();
    return response.body;
  }
);

export const addFavoriteObject = createAsyncThunk(
  'objects/addFavoriteObject',
  async (params) => {
    const response = await api.addFavoriteObject(params.objectId);
    return response.body;
  }
);

export const removeFavoriteObject = createAsyncThunk(
  'objects/removeFavoriteObject',
  async (params) => {
    const response = await api.removeFavoriteObject(params.objectId);
    return response.body;
  }
);

export const getStatusCounts = createAsyncThunk(
  'objects/getStatusCounts',
  async (inParams) => {
    let params;
    if (inParams.length > 1) {
      params = { idList: inParams };
    } else if (inParams.length === 1) {
      params = { parentId: inParams[0] };
    } else {
      params = {};
    }
    const response = await api.getStatusCounts(params);
    return response.body;
  }
);

export const getModifiedTimestamps = createAsyncThunk(
  'objects/getModifiedTimestamps',
  async (inParams) => {
    let params;
    if (inParams.length > 1) {
      params = { idList: inParams };
    } else if (inParams.length === 1) {
      params = { parentId: inParams[0] };
    } else {
      params = {};
    }
    const response = await api.getModifiedTimestamps(params);
    return response.body;
  }
);

export const getObjectTags = createAsyncThunk(
  'objects/getObjectTags',
  async (params) => {
    const response = await api.getObjectTags(params);
    return response.body;
  }
)

export const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    toggleSelectInCurrentFolder: (state, action) => {
      if (state.currentFolderSelectedObjectIds[action.payload]) {
        delete state.currentFolderSelectedObjectIds[action.payload];
      } else {
        state.currentFolderSelectedObjectIds[action.payload] = action.payload;
      }
    },
    selectAllInCurrentFolder: (state) => {
      state.currentFolderSelectedObjectIds = {};
      Object.keys(state.currentFolderSelectableObjectIds).filter(id => state.currentFolderVisibleObjects[id]).forEach(id => {
        state.currentFolderSelectedObjectIds[id] = id;
      });
    },
    deselectAllInCurrentFolder: (state) => {
      state.currentFolderSelectedObjectIds = {};
    },
    setCurrentFolder: (state, action) => {
      const currentFolderObject = state.objectsMapById[action.payload.objectId];

      state.currentFolder = currentFolderObject;
      state.currentFolderSelectableObjectIds = {};

      // Don't reset selected ids when move modal is open
      if (!state.folderTreeVisible) {
        state.currentFolderSelectedObjectIds = {};
      }

      let newObjectIdsForStatusQuery = [];
      if (action.payload?.objectId > 0) {
        // When we are in a folder with parent, we can have statuses with the parent id
        newObjectIdsForStatusQuery = [action.payload.objectId];
      }
      const updateObjectIdsForStatusQuery = () => {
        newObjectIdsForStatusQuery.sort((a, b) => a - b);
        // Update objectIdsForStatusQuery only if its contents would change
        if (!isEqual(newObjectIdsForStatusQuery, state.objectIdsForStatusQuery)) {
          state.objectIdsForStatusQuery = newObjectIdsForStatusQuery;
        }
      }

      if (currentFolderObject && state.objectsMapById[currentFolderObject.id].objects) {
        // We must check if there is any selectable objects in currentFolder
        Object.values(state.objectsMapById[currentFolderObject.id].objects).forEach(o => {

          if (!state.objectsMapById[o.id]) {
            updateObjectIdsForStatusQuery();
            return;
          }

          state.currentFolderVisibleObjects[o.id] = o;
          state.objectsMapById[o.id].rights = evaluateRights(o, currentFolderObject, action.payload.userCompanyId, action.payload.userFeatures, action.payload.folderCompanyFeatures);
          if (state.objectsMapById[o.id].rights.delete || state.objectsMapById[o.id].rights.move) {
            state.currentFolderSelectableObjectIds[o.id] = o.id;
          }

          // Get object id list when were are in some kind of a root folder
          if (typeof action.payload?.objectId === 'number' && action.payload?.objectId <= 0) {
            newObjectIdsForStatusQuery.push(o.id);
          }
        });
      }

      updateObjectIdsForStatusQuery();
    },
    toggleCopyParentRights: (state) => {
      state.copyParentRights = !state.copyParentRights;
    },
    setObjectEditMode: (state, action) => {
      state.objectsMapById[action.payload.id].editMode = action.payload.value;
    },
    setDraggableObject: (state, action) => {
      state.draggableObject = action.payload;
    },
    setObjectTagsModalObject: (state, action) => {
      state.objectTagsModalObject = action.payload;
    },
    setObjectTagValue: (state, action) => {
      state.objectTags[action.payload.id][action.payload.index].value = action.payload.value;
    },
    setFolderTreeSearchString: (state, action) => {
      state.folderTreeSearchString = action.payload;
      
      if (action.payload.length > 2) {
        state.folderTreeSearchHits = [];
        Object.values(state.objectsMapById).forEach(o => {
          if (typeof o.title === 'string' && o.title.toLowerCase().includes(action.payload.toLowerCase())) {
            const ids = buildObjectPath(state.objectsMapById, o).map(p => p.id);
            if (o.objects) {
              const childIds = Object.keys(o.objects).map(key => Number(key));
              ids.push(...childIds);
            }
            state.folderTreeSearchHits.push(...ids);
          }
        });
      }
    },
    setObjectCreateTargetFolderPath: (state, action) => {
      const object = state.objectsMapById[action.payload];
      if (object) {
        state.objectCreateTargetFolderPath = buildObjectPath(state.objectsMapById, object).map(p => p?.id);
      }
    },
    setCreateStep: (state, action) => {
      state.createStep = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchObjects.pending, (state, action) => {
        if (action.meta.arg && !action.meta?.arg?.append) {
          state.fetchStatusPartOne = 'loading';
        } else {
          state.fetchStatus = 'loading';
        }
      })
      .addCase(fetchObjects.fulfilled, (state, action) => {
        if (action.meta.arg && !action.meta?.arg?.append) {
          state.fetchStatusPartOne = 'idle'
        } else {
          state.fetchStatus = 'idle';
        }

        /**
         * action.payload.results = [] is evaluated as array. It must be empty object or problems will arise.
         * Because we set "shared"-key object into it. Js-arrays can only be accessed by int-key: objects[100] etc.
         */
        if (action?.payload?.results?.length === 0) {
          action.payload.results = {};
        }

        if (action.payload.fetched_ts < state.fetchedTs) {
          return;
        }

        if (action.payload.folderSettings) {
          state.folderSettings = action.payload.folderSettings;
        }

        if (action?.meta?.arg?.append &&
            state.objectsById &&
            state.objectsIds
        ) {
          state.objectsById = {...state.objectsById, ...action.payload.results};
          state.objectsIds = [...new Set([...state.objectsIds, ...Object.keys(action.payload?.results || {})])];
        } else {
          state.objectsById = action.payload?.results || {};
          state.objectsIds = Object.keys(action.payload?.results || {});
        }

        for (let o of action.payload?.breadcrumb || []) {
          if (state.objectsById && !state.objectsById[o.id]) {
            state.objectsById[o.id] = o;
          }
        }

        state.fetchedTs = action.payload.fetched_ts;
        // Generate fetchId which contains source (parent or object_id) and fetched_ts
        state.fetchId = (action.payload?.source !== undefined ? action.payload.source : "x") + "-" + action.payload.fetched_ts;

        /**
         * managerInCompanyFeatures should be an object.
         * When payload.managerInCompanyFeatures is empty. It would be considered as [] here (empty array, which is also empty object in php)
         * So make sure, it's initialized as {}, when it's empty. (And it's empty, if it seems like an array here.)
         */
        state.managerInCompanyFeatures = (!action.payload.managerInCompanyFeatures || Array.isArray(action.payload.managerInCompanyFeatures))
          ? {}
          : action.payload.managerInCompanyFeatures;

        // We need a deep copy so we can mutate the object
        state.objectsMapById = JSON.parse(JSON.stringify(state.objectsById));



        // console.debug("state.objectsIds = "+JSON.stringify(state.objectsIds));
        // console.debug("state.objectsById = "+JSON.stringify(state.objectsById));
        // console.debug("state.objectsMapById = "+JSON.stringify(state.objectsMapById));
        const treeResults = buildObjectsTree(state.objectsMapById, action.payload, state.folderSettings);
        state.templateIdCounts = treeResults['templateIdCounts'];
        state.superfolderIdCounts = treeResults['superfolderIdCounts'];
        
        const resultsArray = Object.values(state.objectsById || {});
        state.userObjects = resultsArray.filter((item) => item.owner_id === action.payload.userId);

        if (action.meta?.arg?.favorites) {
          // Objects returned are favorites. Must filter by favorite_created_ts to filter breadcrumb objects away
          state.favoriteObjects = resultsArray.filter((item) => item.favorite_created_ts);
        }
        if (action.meta?.arg?.favorites || action.meta?.arg?.latestDocuments || action.meta?.arg?.myOwn) {
          state.startpageObjectIdsForStatusQuery = resultsArray.map((item) => item.id);
          for (let o of action.payload?.breadcrumb || []) {
            state.startpageObjectIdsForStatusQuery.push(o.id);
          }
        }
      })
      .addCase(fetchObjects.rejected, (state, action) => {
        if (action.meta.arg && !action.meta?.arg?.append) {
          state.fetchStatusPartOne = 'error';
        } else {
          state.fetchStatus = 'error';
        }
      })

      .addCase(deleteObject.pending, (state, action) => {
        state.deleteStatus = 'deleting';

        /*
        * Remove object instantly to make delete feel faster. 
        */
        action.meta.arg.objectIds.forEach(id => {
          // Store original state
          state.deleted[id] = {...state.objectsMapById[state.currentFolder?.id]?.objects?.[id]};
          removeObjectFromCurrentTree(state, id);
        });
      })
      .addCase(deleteObject.fulfilled, (state, action) => {
        state.deleteStatus = 'idle';

        action.meta.arg.objectIds.forEach(id => {
          delete state.deleted[id];
        });

      })
      .addCase(deleteObject.rejected, (state, action) => {
        state.deleteStatus = 'error';

        action.meta.arg.objectIds.forEach(id => {
          if (state.deleted[id]) {
            if (!state.objectsMapById[state.currentFolder.id] || !state.objectsMapById[state.currentFolder.id].objects) {
              return;
            }
        
            // Restore original state
            state.objectsMapById[state.currentFolder.id].objects[id] = {...state.deleted[id]};
            delete state.deleted[id];
          }
        });

      })
      .addCase(moveObject.pending, (state) => {
        state.status = 'moving';

        /*
        * Remove object instantly to make move feel faster. 
        * What to do in error situation? Just fetch again to regain correct objects in correct places?
        */
        removeObjectFromCurrentTree(state, state.draggableObject?.id);

        state.draggableObject = null;
      })
      .addCase(moveObject.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(moveObject.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(createDocument.pending, (state) => {
        state.status = 'creating';
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.status = 'idle';
        
        if (action.meta?.arg?.copy_document_id) {
          // A little bit dummy to fetch status for copied document, but let's use same logic all the time.
          // We do objects fetch anyway, and we don't want to join document to that. 
          // createDocument could return status, but it would be overwritten by objects fetch (and status is not there at all).
          state.objectIdsForStatusQuery.push(action.payload.id);
        }
      })
      .addCase(createDocument.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(saveObject.pending, (state, action) => {
        state.statusById[action.meta.arg.id] = 'saving';
      })
      .addCase(saveObject.fulfilled, (state, action) => {
        state.statusById[action.payload.id] = 'idle';

        // TODO: Update other fields later if there is need.
        state.objectsMapById[action.payload.id].title = action.payload.title;
      })
      .addCase(saveObject.rejected, (state, action) => {
        state.statusById[action.meta.arg.id] = 'error';
      })
      .addCase(savePropagateViewOnShare.pending, (state, action) => {
        state.statusById[action.meta.arg.id] = 'saving';
      })
      .addCase(savePropagateViewOnShare.fulfilled, (state, action) => {
        state.statusById[action.payload.id] = 'idle';

        state.objectsMapById[action.payload.id].propagate_view_on_share = action.payload.propagateViewOnShare;
      })
      .addCase(savePropagateViewOnShare.rejected, (state, action) => {
        state.statusById[action.meta.arg.id] = 'error';
      })
      .addCase(updateFolderSettings.pending, (state, action) => {
        if (action.meta.arg.alt_info_id !== undefined) {  // We must also save value 0
          state.objectsMapById[state.currentFolder.id].alt_info_id = action.meta.arg.alt_info_id;
        } else {
          state.objectsMapById[state.currentFolder.id].folderSettings.items_order = action.meta.arg.items_order;
          state.objectsMapById[state.currentFolder.id].folderSettings.items_order_descending = action.meta.arg.items_order_descending;
        }
      })
      .addCase(updateFolderSettings.fulfilled, (state, action) => {
        if (state.folderSettings 
            && state.folderSettings[action.payload.object_id]
            && action.meta.arg.updateFolderSettingsState
        ) {
          state.folderSettings[action.payload.object_id].items_order = action.meta.arg.items_order;
          state.folderSettings[action.payload.object_id].items_order_descending = action.meta.arg.items_order_descending;
        }
      })

      .addCase(getFolderAltInfoId.pending, (state) => {
        state.folderAltInfoStatus = 'loading';
      })
      .addCase(getFolderAltInfoId.fulfilled, (state, action) => {
        state.folderAltInfoStatus = 'idle';
        if (state.objectsMapById[action.meta.arg]) {
          state.objectsMapById[action.meta.arg].alt_info_id = action.payload.alt_info_id;
        }
      })
      .addCase(getFolderAltInfoId.rejected, (state) => {
        state.folderAltInfoStatus = 'error';
      })

      .addCase(getObjectTags.fulfilled, (state, action) => {
        // Put to own variable instead of objectsMapById to prevent overwrite issues when objects are fetched
        state.objectTags[action.meta.arg] = action.payload.tags;
      })
      .addCase(saveObjectTags.pending, (state) => {
        state.saveTagsStatus = 'saving';
      })
      .addCase(saveObjectTags.fulfilled, (state) => {
        state.saveTagsStatus = 'idle';
        state.objectTagsModalObject = null;
      })
      .addCase(saveObjectTags.rejected, (state) => {
        state.saveTagsStatus = 'error';
      })
      
      .addCase(fetchFavoriteObjects.pending, (state) => {
        state.fetchFavoriteObjectsStatus = 'loading';
      })
      .addCase(fetchFavoriteObjects.fulfilled, (state, action) => {
        state.favoriteObjects = action.payload;
        state.fetchFavoriteObjectsStatus = 'idle';
      })
      .addCase(fetchFavoriteObjects.rejected, (state) => {
        state.fetchFavoriteObjectsStatus = 'error';
      })
      .addCase(addFavoriteObject.pending, (state, action) => {
        state.favoriteStatuses[action.meta.arg.objectId] = 'adding';
      })
      .addCase(addFavoriteObject.fulfilled, (state, action) => {
        const newFavoriteObject = {created_ts: action.payload.created_ts, id: action.payload.object_id};
        state.favoriteObjects.push(newFavoriteObject);

        delete state.favoriteStatuses[action.meta.arg.objectId];
      })
      .addCase(addFavoriteObject.rejected, (state, action) => {
        delete state.favoriteStatuses[action.meta.arg.objectId];
      })
      .addCase(removeFavoriteObject.pending, (state, action) => {
        state.favoriteStatuses[action.meta.arg.objectId] = 'removing';
      })
      .addCase(removeFavoriteObject.fulfilled, (state, action) => {

        state.favoriteObjects = state.favoriteObjects.filter(( obj ) => {
          return obj.id != action.meta.arg.objectId;
        });

        delete state.favoriteStatuses[action.meta.arg.objectId];

      })
      .addCase(removeFavoriteObject.rejected, (state, action) => {
        delete state.favoriteStatuses[action.meta.arg.objectId];
      })

      .addCase(fetchSharedFromCompaniesList.pending, (state) => {
        state.saveTagsStatus = 'saving';
      })
      .addCase(fetchSharedFromCompaniesList.fulfilled, (state, action) => {
        state.saveTagsStatus = 'idle';

        state.sharedFromCompanies = action?.payload?.companies || [];
      })
      .addCase(fetchSharedFromCompaniesList.rejected, (state) => {
        state.saveTagsStatus = 'error';
      })

      .addCase(getStatusCounts.pending, () => {
      })
      .addCase(getStatusCounts.fulfilled, (state, action) => {
        state.statusCounts = action?.payload?.statusCounts || {};
      })
      .addCase(getStatusCounts.rejected, () => {
      })

      .addCase(getModifiedTimestamps.pending, () => {
      })
      .addCase(getModifiedTimestamps.fulfilled, (state, action) => {
        const modifiedTimestamps = action?.payload?.timestamps || {};

        // Set modified_ts to currentFolderVisibleObjects to make sorting and re-rendering work
        // Don't set values to any other variable to keep things as simple as possible
        Object.values(modifiedTimestamps).forEach(tsData => {
          if (tsData?.modified_ts) {
            if (state.currentFolderVisibleObjects[tsData.object_id]) {
              state.currentFolderVisibleObjects[tsData.object_id].modified_ts = tsData.modified_ts;
            }
          }
        });
      })
      .addCase(getModifiedTimestamps.rejected, () => {
      })
  },
});

export const {
  toggleSelectInCurrentFolder,
  toggleListOrder,
  selectAllInCurrentFolder,
  deselectAllInCurrentFolder,
  setCurrentFolder,
  toggleCopyParentRights,
  setObjectEditMode,
  setDraggableObject,
  setObjectTagsModalObject,
  setObjectTagValue,
  setFolderTreeSearchString,
  setObjectCreateTargetFolderPath,
  setCreateStep
} = objectsSlice.actions;

// This is not used by anyone anymore. Use built objectsTree below (objectsMapById).
//export const selectObjects = (state) => state.objects.objectsById;

export const selectObjectsMapById = (state) => state.objects.objectsMapById;
export const selectStatus = (state) => state.objects.status;
export const selectDeleteStatus = (state) => state.objects.deleteStatus;
export const selectSaveTagsStatus = (state) => state.objects.saveTagsStatus;
export const selectFetchStatus = (state) => state.objects.fetchStatus;
export const selectFetchStatusPartOne = (state) => state.objects.fetchStatusPartOne;
export const selectFetchId = (state) => state.objects.fetchId;

/**
 * This is used to check if any of object-fetches is loading.
 * We can reduce unnecessary renders by this way.
 */
export const selectCombinedFetchStatus = (state) => {
  const statusArray = [state.objects.fetchStatusPartOne, state.objects.fetchStatus, state.objects.status];

  if (statusArray.some(s => s === 'error')) {
    return 'error';
  }
  if (statusArray.some(s => s === 'loading')) {
    return 'loading';
  }

  return 'idle';
}

export const selectCurrentFolder = (state) => state.objects.currentFolder;

// In folderSettings there is user specific settings (ordering etc.)
export const selectCurrentFolderSettings = (state) => state.objects.objectsMapById[state.objects.currentFolder?.id]?.folderSettings || null;
// object.alt_info_id is general settings and common for all users
export const selectCurrentFolderAltInfoId = (state) => state.objects.objectsMapById[state.objects.currentFolder?.id]?.alt_info_id || 0;

export const selectCurrentFolderVisibleObjects = (state) => Object.values(state.objects.currentFolderVisibleObjects).filter(o => state.objects.objectsMapById[state.objects.currentFolder?.id]?.objects?.[o.id]);
export const selectCurrentFolderSelectedObjectIds = (state) => Object.keys(state.objects.currentFolderSelectedObjectIds).filter(id => state.objects.objectsMapById[state.objects.currentFolder?.id]?.objects?.[id]);

export const selectHasSelectedObjectsInCurrentFolder = (state) => Object.keys(state.objects.currentFolderSelectedObjectIds).length > 0;
export const selectCurrentFolderHasSelectableAndVisibleObjectIds = (state) => Object.keys(state.objects.currentFolderSelectableObjectIds).filter(id => state.objects.currentFolderVisibleObjects[id]).length > 0;

export const selectIdInCurrentFolderSelectedObjectIds = (state, objectId) => state.objects.currentFolderSelectedObjectIds[objectId];
export const selectIdInCurrentFolderSelectableObjectIds = (state, objectId) => state.objects.currentFolderSelectableObjectIds[objectId];

export const selectManagerInCurrentFolderCompanyFeatures = (state) => state.objects.managerInCompanyFeatures[state.objects.currentFolder?.company_id];

export const selectMappedObjectById = (state, objectId) => state.objects.objectsMapById[objectId];
export const selectExistingObjectId = (state, objectId) => {
  if (state.objects.objectsMapById?.[objectId]) {
    return objectId;
  }
  return null;
}
export const selectCurrentDocumentObject = (state) => state.document.data?.object_id ? state.objects.objectsMapById[state.document.data.object_id] : null;
export const selectCurrentDocumentObjectId = (state) => state.document.data?.object_id || null;
export const selectStatusById = (state, objectId) => state.objects.statusById[objectId] || 'idle';
export const selectDraggableObject = (state) => state.objects.draggableObject;
export const selectObjectTagsModalObject = (state) => state.objects.objectTagsModalObject;
export const selectFolderTreeSearchHits = (state) => state.objects.folderTreeSearchHits;
export const selectFolderTreeSearchString = (state) => state.objects.folderTreeSearchString;
export const selectObjectExists = (state, objectId) => {
  return objectId && state.objects.objectsMapById[objectId] !== undefined;
}
export const selectObjectDocumentId = (state, objectId) => {
  return state.objects.objectsMapById[objectId]?.document_id || 0;
}

export const selectObjectCompanyId = (state, objectId) => {
  return state.objects.objectsMapById[objectId]?.company_id || 0;
}

export const selectParticipantsManageModalObject = (state) => {
  return state.objects.objectsMapById[state.objectRights.participantsManageModalObjectId];
}

/**
 * It is only possible to copy documents. 
 * We want to know how many documents is selected in current folder.
 * And we want to know those document_ids, we send them into BE.
 */
export const selectCurrentFolderSelectedDocumentIds = (state) => {
  let documentIds = [];
  Object.values(state.objects.currentFolderVisibleObjects)?.forEach((object) => {
    if (object?.type === 'document' && Object.keys(state.objects.currentFolderSelectedObjectIds)?.some(o => Number(o) === Number(object?.id))) {
      documentIds.push(object?.document_id);
    }
  });
  return documentIds;
}

export const selectClosestFolder = (state, objectId) => {
  if (!objectId) {
    return state.objects.objectsMapById[0];
  }
  
  const objects = state.objects.objectsById; // objectsById is right here. We don't want to get any "meta-folders" like "shared" and -35 (-companyId)
  const object = state.objects.objectsMapById[objectId];
  if (Constants.FOLDER_TYPES.includes(object?.type) || objectId === 0) {
    return object;
  }
  const objectPath = buildObjectPath(objects, object);
  let lastFolder;
  if (objectPath) {
    for (const o of objectPath) {
      if (Constants.FOLDER_TYPES.includes(o?.type)) {
        lastFolder = o;
      }
    }
  }
  return lastFolder;
}

export const selectObjectsInPath = (state, objectId) => {
  const objectPath = buildObjectPath(state.objects.objectsMapById, state.objects.objectsMapById[objectId]);
  return [{ title: '/', id: 0 }, ...objectPath];
}

export const selectCreateStep = (state) => state.objects.createStep;
export const selectObjectCreateTargetFolderPath = (state) => state.objects.objectCreateTargetFolderPath;

export const selectFloorPlanPartyFolders = createSelector([
  state => state.floorPlan.floorPlan.parent_id,
  state => state.objects.objectsMapById[state.floorPlan.floorPlan.parent_id],
], (
  floorPlanFolderObjectId,
  floorPlanFolderObject,
) => {
  let folders = [{ title: { id: "floorPlan.generalFolder" }, id: floorPlanFolderObjectId }];
  if (!floorPlanFolderObject) {
    return folders;
  }
  folders = folders.concat(Object.values(floorPlanFolderObject?.objects)?.filter(o => Constants.FOLDER_TYPES.includes(o?.type)));
  folders.push({ title: { id: "floorPlan.addNewFolder" }, id: -99 });
  return folders;
});

export const selectFloorPlanRootFolder = (state) => {
  const floorPlanFolderObject = state.objects.objectsMapById[state.floorPlan.floorPlan?.parent_id];
  return state.objects.objectsMapById[floorPlanFolderObject?.parent_id]
}

export const selectTemplateIdCount = (state, templateId) => state.objects.templateIdCounts[templateId] || 0;
export const selectSuperfolderIdCount = (state, superfolderId) => state.objects.superfolderIdCounts[superfolderId] || 0;

export const selectFavoriteObjects = (state) => state.objects.favoriteObjects;
export const selectIsObjectFavorite = (state, objectId) => {
  return state.objects.favoriteObjects.some(o => o.id == objectId);
}
export const selectFetchFavoriteObjectsStatus = (state) => state.objects.fetchFavoriteObjectsStatus;
export const selectFavoriteStatus = (state, objectId) => {
  return state.objects.favoriteStatuses[objectId];
}

export const selectSharedFromCompanies = (state) => state.objects.sharedFromCompanies;

export const selectObjectIdsForStatusQuery = (state) => state.objects.objectIdsForStatusQuery;
export const selectStartpageObjectIdsForStatusQuery = (state) => state.objects.startpageObjectIdsForStatusQuery;
export const selectStatusCountDataForObjectId = (state, id) => state.objects.statusCounts[id];
export const selectModifiedTsForObjectId = (state, id) => state.objects.currentFolderVisibleObjects[id]?.modified_ts;
export const selectFolderAltInfoStatus = (state) => state.objects.folderAltInfoStatus;
export const selectObjectTags = (state, objectId) => state.objects.objectTags[objectId] || [];

export const selectObjectValue = (state, objectId, field) => {
  const object = state.objects.objectsMapById[objectId];
  return object ? object[field] : null;
}
export const selectHasEditRights = (state, objectId) => {
  const object = state.objects.objectsMapById[objectId];
  return (object?.right === 'owner' || object?.right === 'manage' || object?.right === 'edit');
}

export default objectsSlice.reducer;
