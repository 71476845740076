import { apiCall, API, NEW_BE_API } from '../../utils/APIFunctions';
import KPConfig from '../../KPConfig'

export function fetch(companyId, hide_deleted = true) {
  let hideDeleted = hide_deleted ? '&hide_deleted=true' : '';
  return apiCall(`${API}/users?company_id=${companyId}${hideDeleted}`, "GET");
}

export function listForManager(objectId) {
  return apiCall(`${API}/users/list-for-manager/${objectId}`, "GET");
}

export function toggleEditorRightsForUser(userId) {
  return apiCall(`${API}/users/${userId}/editor_rights`,"post");
}

export function toggleAdminRightsForUser(userId) {
  return apiCall(`${NEW_BE_API}/api/n1/users/${userId}/toggle-admin`,"post");
}

export function getUserObjects(userId) {
  return apiCall(`${API}/users/${userId}/objects`,"get");
}

export function sendInvite(params) {
  return apiCall(`${KPConfig.backendUrl}/invite`,"post", JSON.stringify(params));
}

export function removeUserFromCompany(params) {
  let data = {
    remove_shares: params.removeShares,
    update_owner: 1,
    new_owner_id: params.newOwnerId
  };

  return apiCall(`${API}/user-management/remove-from-company/${params.userId}`, "delete", JSON.stringify(data));
}

export function restrictShares(params) {
  return apiCall(`${API}/user-management/restrict-shares/${params.newLevel}`, "put",
    JSON.stringify({userIds: params.userIds}));
}
