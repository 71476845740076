import { apiCall, API, NEW_BE_API } from '../../utils/APIFunctions';

export function newFetchSuperfolders() {
  return apiCall(`${NEW_BE_API}/api/n1/superfolders`, "GET");
}

export function fetchMyLatestSuperfolders() {
  return apiCall(`${NEW_BE_API}/api/n1/superfolders/mylatest`, "GET");
}

export function fetchSuperfolder(superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}`, "GET");
}

export function fetchSuperfolderObjects(superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/templateobjects`, "GET");
}

export function fetchSuperfolderTags(superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/tags`, "GET");
}

export function deleteSuperfolder(superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}`, "DELETE");
}

export function createSuperfolder(params) {
  const data = { name: params.name };
  if (params.copySuperfolderId) {
    data.copy_superfolder_id = params.copySuperfolderId;
  }
  data.noCopySuffix = true;

  return apiCall(`${API}/superfolders`, "POST", JSON.stringify(data));
}

export function saveSuperfolder(params, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}`, "put", JSON.stringify(params));
}

export function addSfTemplateobject(data, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/templateobjects`, "POST", JSON.stringify(data));
}

export function deleteSfTemplateobject(sfTemplateobjectId, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/templateobjects/${sfTemplateobjectId}`, "DELETE");
}

export function saveTag(tag, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/tags/${tag.id}`, "PUT", JSON.stringify(tag));
}

export function addTag(data, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/tags`, "POST", JSON.stringify(data));
}

export function deleteTag(tagId, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/tags/${tagId}`, "DELETE");
}

export function saveSfTemplateobject(object, superfolderId) {
  return apiCall(`${API}/superfolders/${superfolderId}/templateobjects/${object.id}`, "PUT", JSON.stringify(object));
}
