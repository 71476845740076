import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './elasticsearchAPI';
import { getGlobalAbortControllerById } from '../../utils/abortController';
import {fetchObjects} from "../objects/objectsSlice";

const initialState = {};

// This is just used as a default value when state[key] (and state[key].status etc.) is not yet initialized.
const defaultValues = {
  status: 'idle',
  searchTs: 0,
  objects: [],
  q: "",
  from: 0,
  size: 10,
  total: 0,
  currentPage: 1,
  showResults: false,
  searchMade: false,
};

export const searchObjects = createAsyncThunk(
  'objects/searchObjects',
  async (params, { dispatch }) => {
    const globalAbortController = getGlobalAbortControllerById('elasticsearchSlice.searchObjects');
    const response = await api.searchObjects(params, globalAbortController);

    let idList = null;
    if (response?.body?.results) {
      idList = response.body.results.map((item) => item.id);
    }
    
    if (idList) {
      dispatch(fetchObjects({idList: idList, append: true, withBreadcrumb: true}));
    }

    return response?.body;
  }
);

/**
 * Identifier mechanism is used to distinguish between different searches on the same page.
 * In objects-list-view we have "normal" search and "move" search. Move search is used to search for folders to move objects to.
 * Identifier thing works automatically, you just need to make up some string to identifier prop. Like this:
 * <Elasticsearch identifier="search" />
 */

export const elasticsearchSlice = createSlice({
  name: 'elasticsearch',
  initialState,
  reducers: {
    invalidateObjects: (state, action) => {
      const { identifier, value } = action.payload || {};
      if (state[identifier]) {
        state[identifier].objects = [];
        state[identifier].searchMade = false;
        state[identifier].from = 0;
        state[identifier].total = 0;
        state[identifier].currentPage = 0;
        state[identifier].showResults = value;
      }
    },
    resetAll: (state) => {
      state = {};
    },
    setShowResults: (state, action) => {
      const { identifier, value } = action.payload || {};
      if (!state[identifier]) {
        state[identifier] = {};
      }

      state[identifier].showResults = value;
    },
    setQ: (state, action) => {
      const { identifier, value } = action.payload || {};
      if (!state[identifier]) {
        state[identifier] = {};
      }

      state[identifier].q = value;
    },
    setStatus: (state, action) => {
      const { identifier, value } = action.payload || {};
      if (!state[identifier]) {
        state[identifier] = {};
      }

      state[identifier].status = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchObjects.pending, (state, action) => {
        const { identifier } = action.meta?.arg || {};
        if (!state[identifier]) {
          state[identifier] = {};
        }
        state[identifier].status = 'searching';
      })
      .addCase(searchObjects.fulfilled, (state, action) => {
        if (!action?.payload) {
          // Request was aborted
          return;
        }
        const { identifier } = action.meta?.arg || {};
        if (!state[identifier]) {
          state[identifier] = {};
        }

        state[identifier].status = 'idle';

        if (action.payload.fetched_ts < state[identifier].searchTs) {
          return;
        }

        state[identifier].searchTs = action.payload.fetched_ts;
        state[identifier].objects = action.payload.results;
        state[identifier].searchMade = true;
        state[identifier].total = action.payload.total;
        state[identifier].from = action.meta.arg.from || 0;
        state[identifier].currentPage = action.meta.arg.page || 1;
        state[identifier].showResults = true;
      })
      .addCase(searchObjects.rejected, (state, action) => {
        const { identifier } = action.meta?.arg || {};
        if (!state[identifier]) {
          state[identifier] = {};
        }

        state[identifier].status = 'error';
      });
  },
});

export const {
  invalidateObjects,
  setShowResults,
  resetAll,
  setQ,
  setStatus
} = elasticsearchSlice.actions;

export const selectStatus = (state, identifier) => state.elasticsearch[identifier]?.status || defaultValues.status;
export const selectShowResults = (state, identifier) => state.elasticsearch[identifier]?.showResults || defaultValues.showResults;
export const selectObjects = (state, identifier) => state.elasticsearch[identifier]?.objects || defaultValues.objects;
export const selectSearchMade = (state, identifier) => state.elasticsearch[identifier]?.searchMade || defaultValues.searchMade;
export const selectQ = (state, identifier) => state.elasticsearch[identifier]?.q || defaultValues.q;
export const selectFrom = (state, identifier) => state.elasticsearch[identifier]?.from || defaultValues.from;
export const selectSize = (state, identifier) => state.elasticsearch[identifier]?.size || defaultValues.size;
export const selectTotal = (state, identifier) => state.elasticsearch[identifier]?.total || defaultValues.total;
export const selectCurrentPage = (state, identifier) => state.elasticsearch[identifier]?.currentPage || defaultValues.currentPage;

export default elasticsearchSlice.reducer;
