import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './accountAPI';
import * as companySlice from '../company/companySlice';
import * as authSlice from '../auth/authSlice';
import KPConfig from '../../KPConfig.js'

const initialState = {
  status: 'idle',
  passwordStatus: 'idle',
  companyStatus: 'idle',
  emailStatus: 'idle',
  newApiKeyStatus: 'idle',
  signatureDataStatuses: {},
  companyDataStatuses:{}, 
  apiKeyPermissionsStatus: 'idle',
  newApiKeyName: '',
  newApiKeySecret: "",
  showApiKeyCreatedModal: false,
  apiKeys: [],
  showApiKeyPermissionsModal: false,
  isAccountSidebarOpen: true,
  savedTab: "user"
};

export const updateUser = createAsyncThunk(
  'account/updateUser',
  async (params, { dispatch, getState }) => {
    let response = await api.newUpdateUser(params);

    if (params.passwordData) {
      await api.updatePassword(params.passwordData);
    }

    const auth = await dispatch(authSlice.getAuth());

    if (auth?.payload?.success) {

      const companyDataName = getState().company.data.name;

      //Company information tab gets company_name from company data
      if (companyDataName !== auth.payload.company_name) {
        dispatch(companySlice.getUserCompany()); 
      }

      return response.body;
    } else {
      return false
    }
  }
)

export const updatePassword = createAsyncThunk(
  'account/updatePassword',
  async (params) => {
    const response = await api.updatePassword(params);
    return response.body;
  }
)

export const updateEmail = createAsyncThunk(
  'account/updateEmail',
  async (params) => {
    const response = await api.updateEmail(params);
    return response.body;
  }
)

export const updateCompany = createAsyncThunk(
  'account/updateCompany',
  async (params, { dispatch, getState }) => {
    params.companyId = getState().auth.data.company_id;
    let response = await api.updateCompany(params);

    const userCompany = await dispatch(companySlice.getUserCompany());

    if (userCompany.payload) {
      return response?.body;
    } else {
      return false;
    }
  }
)

export const deleteCompanyLogo = createAsyncThunk(
  'account/deleteCompanyLogo',
  async (_, { dispatch }) => {
    const response = await api.deleteCompanyLogo();
    await dispatch(companySlice.getUserCompany());
    return response.body;
  }
)

// This will be obsolete when we move to new BE
// user table is updated with updateUser, user_settings with updateUserSettings
// currently used to update lang, theme and shared_to_me_enabled
export const updateSettings = createAsyncThunk(
  'account/updateSettings',
  async (params, { dispatch}) => {
    // We are just updating user-table columns, why there should be different endpoint? Use normal user-update.
    params.user_id = params.id;
    await api.newUpdateUser(params);

    await dispatch(authSlice.getAuth());
    dispatch(authSlice.setSaveEnabled(false));
  }
)

export const updateUserSettings = createAsyncThunk(
  'account/updateUserSettings',
  async (params, { dispatch}) => {
    await api.newUpdateUserSettings(params);
    await dispatch(authSlice.getAuth());
    dispatch(authSlice.setSaveEnabled(false));
  }
)

export const updateSignatureData = createAsyncThunk(
  'account/updateSignatureData',
  async (params, { dispatch}) => {
    await api.updateSignatureData(params);
    await dispatch(authSlice.getAuth());

  }
)

export const removeUserSignature = createAsyncThunk(
  'account/removeUserSignature',
  async (params, { dispatch }) => {
    await api.removeUserSignature(params);
    await dispatch(authSlice.getAuth());
  }
)

export const getApiKeys = createAsyncThunk(
  'account/getApiKeys',
  async() => {
    const response = await api.getApiKeys();
    return response.body;
  }
)

export const createApiKey = createAsyncThunk(
  'account/createApiKey',
  async(params) => {
    const response = await api.createApiKey(params);
    return response.body;
  }
)

export const deleteApiKey = createAsyncThunk(
  'account/deleteApiKey',
  async(params) => {
    const response = await api.deleteApiKey(params);
    return response.body;
  }
)
export const toggleApiKey = createAsyncThunk(
  'account/toggleApiKey',
  async(params) => {
    const response = await api.toggleApiKey(params);
    return response.body;
  }
)

export const getApiKeyScopes = createAsyncThunk(
  'account/getApiKeyScopes',
  async(params) => {
    const response = await api.getApiKeyScopes(params);
    return response.body;
  }
)

export const saveApiKeyScopes = createAsyncThunk(
  'account/saveApiKeyScopes',
  async(params) => {
    const response = await api.saveApiKeyScopes(params);
    return response.body;
  }
)

export const confirmEmailChange = createAsyncThunk(
  'account/confirmEmailChange',
  async (params, { dispatch }) => {
    const response = await api.confirmEmailChange(params);
    if (response?.body?.status === "completed") {
      await dispatch(authSlice.getAuth());
    }
    return response?.body;
  }
)

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearResponse: (state) => {
      state.emailResponse = null;
    },
    setNewApiKeyName: (state,action) => {
      state.newApiKeyName = action.payload;
    },
    setNewApiKeySecret: (state, action) => {
      state.newApiKeySecret = action.payload;
    },
    setShowApiKeyCreatedModal: (state, action) => {
      state.showApiKeyCreatedModal = action.payload;
    },
    setShowApiKeyPermissionsModal: (state, action) => {
      state.showApiKeyPermissionsModal = action.payload;
    },
    setUserSignatureStatus: (state, action) => {
      state.signatureDataStatuses["userSignature"] = action.payload;
    },
    setApiKeyScopes: (state, action) => {
      switch (action.payload.permission) {
        case 'create':
          state.apiKeyScopes.documents_create = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.journals_create = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.meters_create = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.compilations_create = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.floor_plans_create = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.folders_create = action.payload.isEnabled ? 1 : 0
          break;
        case 'read':
          state.apiKeyScopes.documents_read = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.journals_read = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.meters_read = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.compilations_read = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.floor_plans_read = action.payload.isEnabled ? 1 : 0
          state.apiKeyScopes.folders_read = action.payload.isEnabled ? 1 : 0
          break;
        case 'update':
          state.apiKeyScopes.documents_update = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.journals_update = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.meters_update = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.compilations_update = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.floor_plans_update = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.folders_update = action.payload.isEnabled ? 1 : 0;
          break;
        case 'delete':
          state.apiKeyScopes.documents_delete = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.journals_delete = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.meters_delete = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.compilations_delete = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.floor_plans_delete = action.payload.isEnabled ? 1 : 0;
          state.apiKeyScopes.folders_delete = action.payload.isEnabled ? 1 : 0;
          break;
        default:
          return;
      }
    },
    toggleAccountSidebar: (state) => {
      state.isAccountSidebarOpen = !state.isAccountSidebarOpen;
    },
    saveTab: (state, action) => {
      state.savedTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateEmail.pending, (state) => {
        state.emailStatus = 'loading';
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.emailResponse = { status: action.payload.status, message: action.payload.new_email_msg }
        state.emailStatus = 'idle';
      })
      .addCase(updateEmail.rejected, (state) => {
        state.emailStatus = 'idle';
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(updateSettings.pending, (state,action) => {
        state.settingsStatus = 'loading'; //Is this used?
        state[action.meta.arg.propertyName + "Status"] = "saving";
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.settingsStatus = 'idle';
        state[action.meta.arg.propertyName + "Status"] = "idle";
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.settingsStatus = 'idle';
        state[action.meta.arg.propertyName + "Status"] = "error";
      })
      .addCase(updateUserSettings.pending, (state) => {
        state.userSettingsStatus = 'loading';
      })
      .addCase(updateUserSettings.fulfilled, (state) => {
        state.userSettingsStatus = 'idle';
      })
      .addCase(updateUserSettings.rejected, (state) => {
        state.userSettingsStatus = 'idle';
      })
      .addCase(updateSignatureData.pending, (state,action) => {
        state.signatureDataStatuses[action.meta.arg.propertyName] = "saving";
      })
      .addCase(updateSignatureData.fulfilled, (state, action) => {
        state.signatureDataStatuses[action.meta.arg.propertyName] = "idle";
      })
      .addCase(updateSignatureData.rejected, (state, action) => {
        state.signatureDataStatuses[action.meta.arg.propertyName] = "error";
      })
      .addCase(removeUserSignature.pending, (state,action) => {
        state.signatureDataStatuses[action.meta.arg.propertyName] = "deleting";
      })
      .addCase(removeUserSignature.fulfilled, (state, action) => {
        state.signatureDataStatuses[action.meta.arg.propertyName] = "idle";
      })
      .addCase(removeUserSignature.rejected, (state, action) => {
        state.signatureDataStatuses[action.meta.arg.propertyName] = "error";
      })
      .addCase(updatePassword.pending, (state) => {
        state.passwordStatus = 'saving';
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.passwordStatus = 'idle';
        location.href = KPConfig.backendUrl + '/login';
      })
      .addCase(updatePassword.rejected, (state) => {
        state.passwordStatus = 'error';
      })
      .addCase(updateCompany.pending, (state, action) => {
        state.companyDataStatuses[action.meta.arg.propertyName] = "saving";
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.companyDataStatuses[action.meta.arg.propertyName] = "idle";
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.companyDataStatuses[action.meta.arg.propertyName] = "error";
      })
      .addCase(deleteCompanyLogo.pending, (state, action) => {
        state.companyDataStatuses[action.meta.arg.propertyName] = "deleting";
      })
      .addCase(deleteCompanyLogo.fulfilled, (state, action) => {
        state.companyDataStatuses[action.meta.arg.propertyName] = "idle";
      })
      .addCase(deleteCompanyLogo.rejected, (state, action) => {
        state.companyDataStatuses[action.meta.arg.propertyName] = "error";
      })
      .addCase(createApiKey.pending, (state) => {
        state.newApiKeyStatus = "creating";
      })
      .addCase(createApiKey.fulfilled, (state, action) => {
        const payloadData = action.payload.data;

        state.newApiKeyStatus = "idle";
        state.newApiKeyName = "";
        state.newApiKeySecret = payloadData.secret;
        state.apiKeys.push(
          {
            id:payloadData.id, 
            name: payloadData.name.name, 
            enabled: payloadData.name.enabled, 
            key_id: payloadData.name.key_id
          });
        state.showApiKeyCreatedModal = true;
      })
      .addCase(createApiKey.rejected, (state) => {
        state.newApiKeyStatus = "error";
      })
      .addCase(deleteApiKey.pending, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg);
        if (apiKeyIndex > -1) {
          state.apiKeys[apiKeyIndex].rowStatus = "deleting";
        }
      })
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg);
        if (apiKeyIndex > -1) {
          state.apiKeys.splice(apiKeyIndex, 1);
        }
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg);
        if (apiKeyIndex > -1) {
          state.apiKeys[apiKeyIndex].rowStatus = "error";
        }
      })
      .addCase(getApiKeys.fulfilled, (state, action) => {
        state.apiKeys = action.payload.data;
      })
      .addCase(toggleApiKey.fulfilled, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg.id);
        if (apiKeyIndex > -1) {
          state.apiKeys[apiKeyIndex].enabled = action.payload.enabled;
        }
      })
      .addCase(getApiKeyScopes.fulfilled, (state, action) => {
        state.apiKeyScopes = action.payload;
      })
      .addCase(saveApiKeyScopes.pending, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg.id);
        if (apiKeyIndex > -1) {
          state.apiKeys[apiKeyIndex].rowStatus = "saving";
        }
      })
      .addCase(saveApiKeyScopes.fulfilled, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg.id);
        if (apiKeyIndex > -1) {
          state.apiKeys[apiKeyIndex].rowStatus = "idle";
        }
        //TODO update api key scopes?

      })
      .addCase(saveApiKeyScopes.rejected, (state, action) => {
        const apiKeyIndex = state.apiKeys.findIndex(apiKey => apiKey.id == action.meta.arg.id);
        if (apiKeyIndex > -1) {
          state.apiKeys[apiKeyIndex].rowStatus = "error";
        }
      })
      .addCase(confirmEmailChange.pending, (state) => {
        state.emailStatus = 'loading';
      })
      .addCase(confirmEmailChange.fulfilled, (state, action) => {
        state.emailResponse = { status: action.payload.status, message: action.payload.new_email_msg }
        state.emailStatus = 'idle';
      })
      .addCase(confirmEmailChange.rejected, (state) => {
        state.emailStatus = 'error';
      })
  }
});

export const { 
  clearResponse, 
  setNewApiKeyName, 
  setNewApiKeySecret,
  setShowApiKeyCreatedModal,
  setShowApiKeyPermissionsModal,
  setApiKeyScopes,
  setUserSignatureStatus,
  toggleAccountSidebar,
  saveTab
} = accountSlice.actions;

export const selectEmailResponse = (state) => state.account.emailResponse;
export const selectPasswordStatus = (state) => state.account.passwordStatus;
export const selectStatus = (state) => state.account.status;
export const selectCompanyStatus = (state) => state.account.companyStatus;
export const selectSettingsStatus = (state) => state.account.settingsStatus;
export const selectEmailStatus = (state) => state.account.emailStatus;
export const selectSharedToMeEnabledStatus = (state) => state.account.sharedToMeEnabledStatus || "idle";
export const selectLocaleStatus = (state) => state.account.localeStatus || "idle";
export const selectThemeStatus = (state) => state.account.themeStatus || "idle";
export const selectUnderstandsEditorUseStatus = (state) => state.account.understandsEditorUseStatus || "idle";
export const selectSignatureDataStatuses = (state) => state.account.signatureDataStatuses || {};
export const selectCompanyDataStatuses = (state) => state.account.companyDataStatuses || {};
export const selectApiKeyRowStatus = (state, id) => {
  if (id == null) return null;

  const apiKeyIndex = state.account.apiKeys.findIndex(apiKey => apiKey.id == id);

  if (apiKeyIndex > -1) {
    return state.account.apiKeys[apiKeyIndex]?.rowStatus || null;
  }
  return null;
}
export const selectNewApiKeyStatus = (state) => state.account.newApiKeyStatus;
export const selectNewApiKeyName = (state) => state.account.newApiKeyName;
export const selectNewApiKeySecret = (state) => state.account.newApiKeySecret;
export const selectShowApiKeyCreatedModal = (state) => state.account.showApiKeyCreatedModal;
export const selectApiKeys = (state) => state.account.apiKeys;
export const selectShowApiKeyPermissionsModal = (state) => state.account.showApiKeyPermissionsModal;
export const selectApiKeyScopes = (state) => state.account.apiKeyScopes;
export const selectUserSettingsStatus = (state) => state.account.userSettingsStatus;

export default accountSlice.reducer;
