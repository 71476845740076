import { apiCall, API, NEW_BE_API } from '../../utils/APIFunctions';

export function newFetch(opts = {}) {
  let source = opts.parentId || 0;

  if (opts.myOwn) {
    source = "own";
  } else if (opts.latestDocuments) {
    source = "latest-documents";
  } else if (opts.favorites) {
    source = "favorites";
  }

  if (opts.idList) {
    let params = {idList: opts.idList};
    if (opts.withBreadcrumb) {
      params.withBreadcrumb = true;
    }
    return apiCall(`${NEW_BE_API}/api/n1/objects/list`, "POST", JSON.stringify(params));
  }

  return apiCall(`${NEW_BE_API}/api/n1/objects/${source}`, "GET");
}

export function fetchSharedFromCompaniesList() {
  return apiCall(`${NEW_BE_API}/api/n1/objects/shared`, "GET");
}

export function getStatusCounts(params) {
  return apiCall(`${NEW_BE_API}/api/n1/objects/status-counts`, "POST", JSON.stringify(params));
}

export function getModifiedTimestamps(params) {
  return apiCall(`${NEW_BE_API}/api/n1/objects/modified-timestamps`, "POST", JSON.stringify(params));
}

export function searchFromElastic(params) {
  return apiCall(`${API}/elasticsearch/objects/search?q=${params.q}&size=${params.size}&from=${params.from}`,"GET");
}

export function fetchChanged(lastUpdatedTimestamp) {
  return apiCall(`${API}/kp_objects?getKeyed=true&updatedAfter=${lastUpdatedTimestamp}&superView=1`, "GET");
}

export function deleteObject(id) {
  if (!id) {
    return false;
  }

  return apiCall(`${API}/kp_objects/${id}`, "DELETE");
}

export function moveObject(objectId, targetObjectId, copyParentRights) {
  const data = { target_id: targetObjectId, copy_parent_rights: copyParentRights };
  return apiCall(`${API}/kp_objects/${objectId}/moveToFolder`, "put", JSON.stringify(data));
}

export function newCreateDocument(data) {
  return apiCall(`${NEW_BE_API}/api/n1/documents`, "post", JSON.stringify(data));
}

export function createFolder(data) {
  return apiCall(`${API}/o/folders`, "post", JSON.stringify(data));
}

export function saveObject(data) {
  return apiCall(`${API}/kp_objects/${data.id}`, "put", JSON.stringify(data));

  // Enable this when new backend is ready
  // return apiCall(`${NEW_BE_API}/api/n1/objects/${data.id}`, "put", JSON.stringify(data));
}

export function savePropagateViewOnShare(data) {
  return apiCall(`${API}/kp_objects/${data.id}/propagate_view_on_share`, "post", JSON.stringify({ propagateViewOnShare: data.value }));
}

export function updateFolderSettings(data) {
  return apiCall(`${API}/kp_objects/${data.id}/folderSettings`, "put", JSON.stringify(data));
}

export function fetchFolderSettings() {
  return apiCall(`${API}/folderSettings`, "GET");
}

export function getFolderAltInfoId(objectId) {
  return apiCall(`${NEW_BE_API}/api/n1/objects/${objectId}/alt-info-id`, "GET");
}

export function getObjectTags(objectId) {
  return apiCall(`${NEW_BE_API}/api/n1/objects/${objectId}/tags`, "GET");
}

export function saveObjectTag(objectId, tagId, tagValue) {
  return apiCall(`${API}/kp_objects/${objectId}/tags/${tagId}`,"put", JSON.stringify(tagValue));
}

export function fetchFavoriteObjects() {
  return apiCall(`${API}/favorite_objects`, "GET");
}

export function addFavoriteObject(objectId) {
  return apiCall(`${API}/favorite_objects/${objectId}`, "POST");
}

export function removeFavoriteObject(objectId) {
  return apiCall(`${API}/favorite_objects/${objectId}`, "DELETE");
}

export function fetchMyObjects() {
  return apiCall(`${API}/my_objects`, "GET");
}