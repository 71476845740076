import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './companyAPI';

const initialState = {
  data: { features: {} },
  billingData: {},
  initialCompanyInformation: {}, //Used in CompanyInformation component
  newAltInfo: {
    company_name: '',
    company_info: '',
    logo: ''
  },
  status: 'idle',
  altInfoStatus: 'idle',
};

export const getUserCompany = createAsyncThunk(
  'company/get',
  async () => {
    let response = await api.newGet();

    return response.body;
  },
  {
    condition: (opts) => {
      // This will be run before the pending reducer
      return !opts?.skipFetch;
    }
  }
);

export const createCompanyAltInfo = createAsyncThunk(
  'company/createCompanyAltInfo',
  async (params, { dispatch, getState }) => {
    const response = await api.createCompanyAltInfo({ ...getState().company.newAltInfo, logo: params.file });
    const userCompany = await dispatch(getUserCompany());

    if (userCompany.payload) {
      return response?.body;
    } else {
      return false;
    }
  }
)

export const deleteCompanyAltInfo = createAsyncThunk(
  'company/deleteCompanyAltInfo',
  async (params, { dispatch }) => {
    const response = await api.deleteCompanyAltInfo(params.altInfoId);
    const userCompany = await dispatch(getUserCompany());

    if (userCompany.payload) {
      return response?.body;
    } else {
      return false;
    }
  }
)

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyName: (state, action) => {
      state.data.name = action.payload;
    },
    setCompanyInfo: (state, action) => {
      state.data.info = action.payload;
    },
    setCompanyLogo: (state, action) => {
      state.data.logo = action.payload;
    },
    setAltInfoCompanyName: (state, action) => {
      state.newAltInfo.company_name = action.payload;
    },
    setAltInfoCompanyInfo: (state, action) => {
      state.newAltInfo.company_info = action.payload;
    },
    setAltInfoCompanyLogo: (state, action) => {
      state.newAltInfo.logo = action.payload;
    },
    setCompanyTampuuriIntegration: (state, action) => {
      state.data.features.tampuuri_integration = action.payload;
    },
    setCompanyApiIntegration: (state, action) => {
      state.data.features.api = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserCompany.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
        state.data.compilationsfolderId = action.payload?.compilations_folder_id; // keep old BE format too
        state.initialCompanyInformation =
        {
          name: action.payload.name,
          info: action.payload.info,
          logo: action.payload.logo
        };
      })
      .addCase(getUserCompany.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createCompanyAltInfo.pending, (state) => {
        state.altInfoStatus = "saving";
      })
      .addCase(createCompanyAltInfo.fulfilled, (state) => {
        state.altInfoStatus = "idle";
        state.newAltInfo = {
          company_name: '',
          company_info: '',
          logo: ''
        }
      })
      .addCase(createCompanyAltInfo.rejected, (state) => {
        state.altInfoStatus = "error";
      })
      .addCase(deleteCompanyAltInfo.pending, (state) => {
        state.altInfoStatus = "deleting";
      })
      .addCase(deleteCompanyAltInfo.fulfilled, (state) => {
        state.altInfoStatus = "idle";
      })
      .addCase(deleteCompanyAltInfo.rejected, (state) => {
        state.altInfoStatus = "error";
      })
  }
});

// Destructure and export the plain action creators
export const {
  setCompanyName,
  setCompanyInfo,
  setCompanyLogo,
  setAltInfoCompanyName,
  setAltInfoCompanyInfo,
  setAltInfoCompanyLogo,
  setCompanyTampuuriIntegration,
  setCompanyApiIntegration
} = companySlice.actions

export const selectFeatures = (state) => state.company.data.comp_features;
export const selectCompanyName = (state) => state.company.data?.name;
export const selectCompanyInfo = (state) => state.company.data?.info;
export const selectCompanyLogo = (state) => state.company.data?.logo;
export const selectCompanyId = (state) => state.company.data?.id;
export const selectCompanyIndividual = (state) => state.company.data?.individual;
export const selectCompanyCategory = (state) => state.company.data?.billing_info?.category;
export const selectSubscriptionStatus = (state) => state.company.data.subscription_status;
export const selectInitialCompanyInformation = (state) => state.company.initialCompanyInformation;
export const selectSubscriptionCancelAtPeriodEnd = (state) => state.company.data.subscription_cancel_at_period_end;
export const selectHasOnlyCanceledSubscriptions = (state) => state.company.data.has_only_canceled_subscriptions;
export const selectCompanyTemplates = (state) => {
  return {
    doctemplates: state.company.data.comp_doctemplates,
    metrictemplates: state.company.data.comp_metrictemplates,
    journaltemplates: state.company.data.comp_journaltemplates
  };
}
export const selectSubscriptionPlanIdentifier = (state) => state.company.data.subscription_plan_identifier;
export const selectCompanyAltInfos = (state) => state.company.data?.alt_infos;
export const selectNewAltInfo = (state) => state.company.newAltInfo;
export const selectAltInfoStatus = (state) => state.company.altInfoStatus;
export const selectStatus = (state) => state.company.status;
export const selectHasAdminToken = (state) => state.company.data?.hasAdminToken;
export const selectUserHasGroupOrSuperAdminRightsForCompany = (state) => state.company.data?.userHasGroupOrSuperAdminRightsForCompany;
export const selectAdminToken = (state) => state.company.data?.adminToken;
export const selectEnterprisePriceSet = (state) => state.company.data?.subscription_enterprise_price_set;
export const selectIsValidForEnterpriseSetup = (state) => state.company.data?.subscription_valid_for_enterprise_setup;
export const selectShowPaymentReminder = (state) => state.company.data?.showPaymentReminder;
export const selectBillingInfoIsValid = (state) => state.company.data?.billing_info?.is_valid;

// First variable is from old BE, second from new BE
export const selectCompilationsCreateEnabled = (state) => state.company.data?.features?.compilationsCreateEnabled > 0 || state.company.data?.compilations_create_enabled > 0;

export const selectSubscriptionPlanChargeApi = (state) => state.company.data?.subscription_plan_charge_api !== 0;

export default companySlice.reducer;
